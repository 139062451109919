<template>
  <div style="color: white; height: 100vh" class="text-center px-2 bg-dark" >
    <b-form-select class="mt-4" style="width:150px" v-model="botSelected" :options="bots"></b-form-select>
    <h1 style="margin-bottom:30px; margin-top:20px">Monitoreo de posiciones</h1>
    <hr>
    <b-row>
       <b-col sm='12' md='6' class="pb-5 bg-dark border p-2 " style="border: none!important; height: 920px;" >
           <b-list-group v-if="accountInfo != null" class="mb-2 bg-dark">
             <b-list-group-item class="bg-dark " style="color:lightgray ; border: none;padding-left: 5px;padding-right: 5px;">
                <span style="float:left" >Balance:</span> <span style="float:right">{{ Math.round(accountInfo.balance * 100) / 100}}</span>
             </b-list-group-item>
             <b-list-group-item class="bg-dark " style="color:lightgray ;border: none;padding-left: 5px;padding-right: 5px;">
                <span style="float:left" >Capital:</span> <span style="float:right">{{ Math.round(accountInfo.equity * 100) / 100}}</span>
             </b-list-group-item>
             <b-list-group-item class="bg-dark " style="color:lightgray ;border: none;padding-left: 5px;padding-right: 5px;">
                <span style="float:left" >Margen:</span> <span style="float:right">{{ Math.round(accountInfo.margin * 100) / 100}}</span>
             </b-list-group-item>
             <b-list-group-item class="bg-dark " style="color:lightgray ;border: none;padding-left: 5px;padding-right: 5px;">
                <span style="float:left" >Margen Libre:</span> <span style="float:right">{{ Math.round(accountInfo.freeMargin * 100) / 100}}</span>
             </b-list-group-item>
             <b-list-group-item class="bg-dark " style="color:lightgray ;border: none;padding-left: 5px;padding-right: 5px;">
                <span style="float:left" >Nivel de margen (%):</span> <span style="float:right">{{ Math.round(accountInfo.marginLevel * 100) / 100}}</span>
             </b-list-group-item>
           </b-list-group>

           <strong class="float-left bg-dark" style="float:left;padding-left: 5px;">Posiciones abiertas</strong> 
           <span style="float:right; padding-right:10px" :class=" totalAbiertas <= 0 ? 'text-danger' : 'text-success'" >{{Math.round(totalAbiertas * 100) / 100}}</span>
           <br>
           <b-list-group class="overflow-auto " style="max-height:500px" >
            <b-list-group-item  class="bg-dark text-white" style="border: none; border-bottom: 1px solid lightslategray;" v-for="(posicion,i) in posicionesAbiertas" :key="i">
              <b-row>
                <b-col class="text-left" style="text-align:left">
                  <strong style="font-size:14px" >{{posicion.symbol}}</strong>, <span :class=" posicion.type === 'POSITION_TYPE_SELL' ? 'text-danger' : 'text-primary'" style="font-size:14px">{{posicion.type === 'POSITION_TYPE_SELL' ? 'Venta': 'Compra'}} {{posicion.volume}}</span> <br>
                  <span style="color:lightgray">{{posicion.openPrice}} -> {{posicion.currentPrice}}</span>
                  <br>
                  <span>{{ trader(posicion.comment) != null ? trader(posicion.comment).name : 'Sin trader'}}</span>
                </b-col>
                <b-col style="text-align:right">
                  <span :class=" posicion.profit <= 0 ? 'text-danger' : 'text-success'" style="vertical-align: -webkit-baseline-middle;">{{Math.round(posicion.profit * 100) / 100}}</span>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
       </b-col>


       <b-col class="pb-5 border p-2  bg-dark" style="max-height: 920px; border: none!important; border-left:1px solid lightslategray">
          <h5>REPORTE</h5>
          <!--REPORTE POR DÍA-->
          <b-row>
             <b-col>
               <input type="date" v-model="daySelectedS" class="mb-2"/>
             </b-col>
             <b-col>
               <input type="date" v-model="daySelected" class="mb-2"/>
             </b-col>
          </b-row>

           <b-list-group class="mb-2 bg-dark ">
             <b-list-group-item class="bg-dark " style="color:lightgray;border: none;padding-left: 5px;padding-right: 5px;">
                <span style="float:left" >Ganadas:</span> <span style="float:right">{{todayReport.win}}</span>
             </b-list-group-item>
             <b-list-group-item class="bg-dark" style="color:lightgray;border: none;padding-left: 5px;padding-right: 5px;">
                <span style="float:left" >Perdidas:</span> <span style="float:right">{{todayReport.lost}}</span>
             </b-list-group-item>
             
             <b-list-group-item class="bg-dark " style="color:lightgray;border: none;padding-left: 5px;padding-right: 5px;">
                <span style="float:left" >Total:</span> <span :class=" todayReport.totalA <= 0 ? 'text-danger' : 'text-success'"  style="float:right">{{ Math.round(todayReport.totalA * 100) / 100}}</span>
             </b-list-group-item>
           </b-list-group>

           <strong class="float-left" style="float:left;padding-left: 5px;">Resultados por tipo</strong> <br>
           <b-list-group class="mb-4 bg-dark text-white">
             <b-list-group-item class="bg-dark text-white" style="border: none; border-bottom: 1px solid lightslategray; padding-left: 5px;padding-right: 5px;">
                <span style="float:left" >Compra:</span> <span :class=" todayReport.buy <= 0 ? 'text-danger' : 'text-success'" style="float:right">{{Math.round(todayReport.buy * 100) / 100}}</span>
             </b-list-group-item>
             <b-list-group-item class="bg-dark text-white" style="border: none; border-bottom: 1px solid lightslategray; padding-left: 5px;padding-right: 5px;">
                <span style="float:left" >Venta:</span> <span :class=" todayReport.sell <= 0 ? 'text-danger' : 'text-success'" style="float:right">{{Math.round(todayReport.sell * 100) / 100}}</span>
             </b-list-group-item>
           </b-list-group>
           
           <strong class="float-left" style="float:left;padding-left: 5px;">Resultados por estrategia</strong> <br>
           <b-list-group class="overflow-auto mb-4 bg-dark text-white" style="max-height:200px ">
             <b-list-group-item class="bg-dark text-white" style="border: none; border-bottom: 1px solid lightslategray; padding-left: 5px;padding-right: 5px;" v-for="(t,i) in todayReport.tradingE" :key="i">
                <span style="float:left" >{{t.name}}:</span> <span :class=" t.value <= 0 ? 'text-danger' : 'text-success'" style="float:right">{{Math.round(t.value * 100) / 100}}</span>
             </b-list-group-item>
           </b-list-group>

           <strong class="float-left" style="float:left;padding-left: 5px;">Resultados por trader</strong> <br>
           <b-list-group class="overflow-auto bg-dark"  style="max-height:250px ">
             <b-list-group-item  class="bg-dark text-white" style="border: none; border-bottom: 1px solid lightslategray;padding-left: 5px;padding-right: 5px;" v-for="(t,i) in todayReport.traders" :key="i">
                <span style="float:left" >{{t.name}}:</span> <span :class=" t.acumulado <= 0 ? 'text-danger' : 'text-success'" style="float:right">{{Math.round(t.acumulado * 100) / 100}}</span>
             </b-list-group-item>
           </b-list-group>
           
           <b-button class="text-center mt-3" variant='primary'>
               <download-excel :name='`posiciones ${daySelectedS} a ${daySelected}.xls`' :data="todayReport.allPositions">Descargar</download-excel>
           </b-button>
       </b-col>
    </b-row>
    
  </div>
</template>

<script>
import io from 'socket.io-client';
import { ClientTable} from 'vue-tables-2-premium';
import Vue from 'vue'
import traders from './traders.json'
import moment from 'moment'
const download = require("downloadjs")
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.use(ClientTable);

export default {
    data() {
        return {
            socket : io('apimonitor.menitalabs.com'),
            botSelected: 'bot1',
            bots:[
               { value: 'bot1', text: 'BOT 1 DEMO' }, 
               { value: 'bot2', text: 'BOT 2 DEMO' }, 
            ],
            traders : traders,
            data:null,
            daySelectedS: moment().format('YYYY-MM-DD'),
            daySelected : moment().format('YYYY-MM-DD')
        }
    },
    computed:{
       posicionesAbiertas(){
          if(this.data != null){
             return this.data[this.botSelected].positions
          }else{
            return []
          }
       },
       accountInfo(){
          if(this.data != null){
             return this.data[this.botSelected].info
          }else{
            return null
          }
       },
       deals(){
          if(this.data != null){
             let deals =  this.data[this.botSelected].deals
             const today = moment(this.daySelected + ' 23:59:59')
             const yesterday = moment(this.daySelectedS + ' 00:00:00')
             return deals.filter(x => {
               let compare = moment(x.brokerTime)
               return compare.isBetween(yesterday, today)
             })
          }else{
            return []
          }
       },
       todayReport(){
          let totalA = 0
          let lost = 0
          let win = 0
          this.traders.map(t => t.acumulado = 0)
          let traders = this.traders
          let tradingE = []
          let buy = 0
          let sell = 0
          let slBreak = []
          let lostPositions= []
          let allPositions = []

          this.deals.map(d =>{
            if(d.profit != 0 && (d.type === 'DEAL_TYPE_BUY' || d.type === 'DEAL_TYPE_SELL')){
                let trader = traders.find( t => d.comment.includes(t.userName))
                if(trader.id === 1601766740) return;
                if(trader != undefined) trader.acumulado += d.profit;

                totalA += d.profit; 
                if(d.profit > 0) win++; else lost++;

                if(d.type === 'DEAL_TYPE_SELL') buy+= d.profit; else sell+=d.profit;

                if(d.profit <= -8) slBreak.push(d);

                if(d.profit < 0) lostPositions.push(d);

                allPositions.push(d)

                //sacar resultado por tipo
                let tipo = d.comment.split('_')
                tipo = tipo[1]
                let findTipo = tradingE.find(te => te.name === tipo)
                if(findTipo != undefined){
                    findTipo.name = tipo
                    findTipo.value +=d.profit
                }else{
                    tradingE.push({
                      name : tipo,
                      value : d.profit
                    })
                }
            }
          })

          allPositions.map( p => {
             p.trader = traders.find( t => p.comment.includes(t.userName)).name
             let tipo = p.comment.split('_')
             tipo = tipo[1]
             p.estrategia = tipo
          })
          return {
             totalA,
             lost,
             win,
             buy,
             sell,
             traders : traders.filter( t => t.acumulado != 0),
             tradingE,
             slBreak,
             lostPositions,
             allPositions 
          }
       },
       totalAbiertas(){
          let total = 0
          if(this.posicionesAbiertas.length > 0){
             this.posicionesAbiertas.map( x => total+= x.profit)
             return total
          }else{
            return total
          }
       }
    },
    methods: {
      downloadPositions(positions){
        download(JSON.stringify(positions), "posiciones.json", "text/plain");  
      },
      trader(comment){
          let trader = traders.find( x => comment.includes(x.userName))
          return trader
      },
      dateFormat(date){
        let today = new Date(date);
        let dd = today.getDate();

        let mm = today.getMonth()+1; 
        let yyyy = today.getFullYear();
        if(dd<10) 
        {
            dd='0'+dd;
        } 

        if(mm<10) 
        {
            mm='0'+mm;
        } 
        return yyyy + '-' + mm + '-' +dd + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      }
    },
    created(){
      this.socket.on('bot', (data) => {
        this.data = data
      });
    }
}
</script>

<style>
  #openPositions .VueTables__search-field{
    display: none;
  }
</style>